@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.effect-industry-pdf-warp {
  border-radius: 0 0 20px 20px;
  margin-top: 0;
  .chart-demo {
    margin-top: 30px;
  }
  .industry-chart-warp-pdf {
    height: 400px;
    flex: 1;
  }
  .industry-income-warp-pdf {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
    .title {
      font-size: 16px;
      font-weight: 600;
      color: $black;
      line-height: 22px;
      padding-bottom: 8px;
      border-bottom: 1px solid $ec;
    }
    .left-chart {
      width: 60%;
    }
    .right-chart {
      width: calc(40% - 10px);
    }
  }
}
