@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.tab-time-warp-list {
  display: inline-flex;
  justify-content: flex-start;
  border: 1px solid $ec;
  padding: 2px;
  border-radius: 5px;
  height: 36px;
  .tab-item {
    padding: 5px 20px;
    color: $c55;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    height: 30px;
  }
}
