@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.industry-modal_0110 {
  @include white-bg(1);
  position: absolute;
  width: 520px;
  left: 50%;
  top: 50%;
  margin-top: -180px;
  margin-left: -260px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  .title-015 {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0 10px 0 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);

    span {
      padding-top: 4px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      border-top: 2px solid $secondary;
      color: $c333;
    }
  }
  .modal-0111 {
    height: 300px;
    display: flex;
    overflow: visible;

    .industry-0101 {
      width: 148px;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding: 10px 0;
      border-right: 1px solid rgba(0, 0, 0, 0.08);
    }
    .industry-0102 {
      display: inline-flex;
      flex-direction: column;
    }
    .industry-0201 {
      flex: 1;
      // overflow-y: auto;
      overflow-y: visible;
      padding: 5px 0 10px 0;

      .item-111 {
        position: relative;
        display: inline-flex;

        .tip-111 {
          display: none;
          position: absolute;
          top: -16px;
          left: 50%;
          margin-left: -40px;
          width: 80px;
          // display: inline-flex;
          justify-content: center;
          padding: 6px 0;
          font-size: 12px;
          font-weight: 500;
          border-radius: 4px;
          line-height: 1;
          z-index: 999;
          color: $fff;
          background-color: rgba(0, 0, 0, 0.7);

          .tri-10 {
            position: absolute;
            top: 24px;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 5px solid rgba(0, 0, 0, 0.7);
          }
        }
        &:hover {
          .tip-111 { display: inline-flex; }
        }
      }
    }
  }
  .modal-0112 {
    height: 300px;
    overflow-y: auto;
    padding: 5px 0 10px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
  .modal-0113 {
    padding: 5px 20px 15px 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
  }
}
