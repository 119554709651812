@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.second-header_market {
  @include min-width();
  @include max-width();
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    display: inline-flex;
    align-items: center;
    margin-left: 60px;
    border-bottom: 2px solid $fff;
    color: $C000;
    opacity: 0.65;

    &.active {
      opacity: 1;
      border-bottom: 2px solid $C000;
    }
    &:first-child {
      margin-left: 0;
    }
  }
  .line-1515 {
    display: inline-flex;
    width: 2px;
    height: 20px;
    margin-left: 40px;
    background-color: $ec;
  }
}
