@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.company-recruitment-warp {
  .tops-title-warp {
    display: flex;
    justify-content: space-between;
    .wealfare-title-111 {
      width: 100%;
    }
  }
  .company-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    margin-top: 20px;
    border-radius: 8px;
    .company-content {
      .table {
        display: flex;
        justify-content: space-between;
        .right {
          width: 350px;
          margin-left: 10px;
          background-color: $fff;
          border-radius: 8px;
          padding: 10px;
          .industry {
            p {
              line-height: 25px;
              font-size: 14px;
              color: $c55;
              @include mont-font();
              &:nth-child(1) {
                line-height: auto;
                font-weight: 600;
              }
            }
          }
        }
        .company-svg-warp {
          flex: 1;
          border-radius: 8px;
          background-color: $fff;
          padding: 15px;
          .company-svg {
            height: 300px;
            width: 100%;
          }
          .detail {
            @include black-color(0.85);
            font-size: 12px;
            padding: 0 25px;
            span {
              margin-right: 20px;
            }
            i {
              @include mont-font();
              color: $primary;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .company-tab {
    margin: 20px 0;
  }
  .company-rank-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    margin-top: 20px;
    border-radius: 8px;
    .company-head-content {
      display: flex;
      justify-content: space-between;
      .right-con {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          display: flex;
          justify-content: space-between;
          border: 1px solid $ec;
          border-radius: 4px;
          padding: 5px 5px 5px 25px;
          width: 100px;
          align-items: center;
          margin-left: 5px;
          text-align: center;
          cursor: pointer;
          span {
            background-color: $primary;
            color: $fff;
            border-radius: 5px;
            margin-right: 5px;
            padding: 0 5px;
          }
        }
      }
    }
    .company-rank-content {
      .table {
        display: flex;
        justify-content: space-between;
        .right {
          width: 350px;
          margin-left: 10px;
          background-color: $fff;
          border-radius: 8px;
          padding: 10px;
        }
        .svg-group {
          flex: 1;
          border-radius: 8px;
          background-color: $fff;
          padding: 15px;
          .tigs {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 10px);
            p {
              width: calc(50% - 10px);
              text-align: right;
              padding-right: 10px;
            }
          }
          .company-svg-warp {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            height: 510px;
            overflow-y: auto;
            .items {
              width: calc(50% - 10px);
              display: flex;
              justify-content: space-between;
              margin: 15px 0;
              padding-right: 10px;
              .svg-left {
                @include dark-border(0.1);
                width: 66px;
                height: 66px;
                border-radius: 50%;
                position: relative;
                padding: 10px;
                box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
                background-color: $fff;
                b {
                  display: block;
                  width: 46px;
                  height: 46px;
                  background-size: cover;
                  background-position: center center;
                  background-repeat: no-repeat;
                  border-radius: 50%;
                  -webkit-border-radius: 50%;
                  -moz-border-radius: 50%;
                  -ms-border-radius: 50%;
                  -o-border-radius: 50%;
                }
                span {
                  @include mont-font();
                  text-align: center;
                  color: $error;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  font-size: 10px;
                  font-weight: 600;
                  width: 36px;
                  height: 14px;
                  background: $white;
                  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
                  border-radius: 7px;
                }
              }
              .svg-mid {
                flex: 1;
                margin: 0 10px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: 66px;
                p {
                  span {
                    @include mont-font();
                  }
                  &:nth-child(1) {
                    font-size: 16px;
                    color: $black;
                  }
                  &:nth-child(2) {
                    @include mont-font();
                    font-size: 14px;
                    color: $secondary;
                  }
                  &:nth-child(3) {
                    background-color: $f2;
                    height: 10px;
                    border-radius: 8px;
                    position: relative;
                    b {
                      display: block;
                      width: 50%;
                      height: 10px;
                      border-radius: 8px;
                      position: absolute;
                      left: 0;
                      top: 0;
                      background: linear-gradient(270deg, $fad9 0%, $f76b 100%);
                    }
                  }
                }
              }
              .svg-right {
                height: 66px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                p {
                  display: flex;
                  justify-content: flex-start;
                  @include mont-font();
                  &:nth-child(1) {
                    color: $black;
                  }
                  .up {
                    transform: rotate(90deg);
                    display: block;
                  }
                  .down {
                    transform: rotate(-90deg);
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .company-rank-tab {
    margin: 20px 0;
  }
}

.model-cityList-warp {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .model-content-warp {
    width: 520px;
    background-color: $fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .model-top {
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      padding: 0 20px;
      .model-title {
        font-size: 16px;
        color: $c333;
        font-weight: 500;
        border-top: 2px solid $secondary;
      }
    }
    .model-content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      overflow: auto;
      height: 300px;
      border-bottom: 1px solid $ec;
      border-top: 1px solid $ec;
      .model-cityList {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .items {
          border-radius: 3px;
          color: $black;
          font-size: 14px;
          padding: 10px 20px;
          margin-top: 3px;
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-left: 3px;
        }
        .active {
          @include dark-bg(0.1);
          margin-top: 3px;
          border-radius: 3px;
          color: $primary;
          font-size: 14px;
          padding: 10px 20px;
          margin-top: 3px;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-weight: 600;
          margin-left: 3px;
        }
      }
    }
    .sure-warp {
      display: flex;
      justify-content: flex-end;
      margin: 20px;
      .sure {
        width: 80px;
        height: 36px;
        font-size: 14px;
        background-color: $secondary;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $fff;
        cursor: pointer;
      }
    }
  }
}
