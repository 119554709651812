@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.menu_10 {
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: $fff;

  .menu-item_10 {
    position: relative;
    display: inline-flex;
    flex-direction: column;

    b {
      @include black-color(0.65);
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
    }
  }
  .little_10 {
    display: inline-flex;
    width: 1px;
    height: 70px;
    margin: 0 30px;
    background-color: $ec;
  }
}
