@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.header-pop_100 {
  @include white-bg(1);
  display: flex;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  z-index: 9;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: $fff;

  .pop-1010 {
    width: 100%;
    min-width: 1280px;
    max-width: 1920px;
    display: flex;
  }

  .pop-box_100 {
    position: relative;
    width: 1150px;
    display: flex;

    .tri-10 {
      position: absolute;
      top: -9px;
      width: 0;
      height: 0;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid $f7;
    }
    .tri-11 { left: 215px; }
    .tri-12 { left: 390px; }
    .tri-13 { left: 690px; }

    .box-10 {
      padding: 40px 0 20px 0;

      .search-11 {
        display: flex;
        width: 780px;
        padding-bottom: 30px;
        border-bottom: 1px solid $ec;
      }
      .search-101 {
        flex: 1;
        height: 36px;
        display: inline-flex;
        flex-wrap: wrap;
        margin-left: 10px;
        overflow: hidden;
      }
      .card-11 {
        display: flex;
        flex-wrap: wrap;
        width: 780px;
        padding: 0 0 10px 0;
        cursor: pointer;

        .card-101 {
          display: inline-flex;
          flex-direction: column;
          width: 240px;
          height: 100px;
          margin-top: 20px;
          margin-right: 30px;
          padding: 20px 10px 10px 20px;
          border-radius: 5px;
          border: 3px solid;
          border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(243, 245, 248, 1)) 3 3;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);
          background: linear-gradient(180deg, #F3F5F8 0%, #FFFFFF 100%);

          &:nth-child(3), &:nth-child(6) { margin-right: 0; }
          .line-101 {
            display: inline-flex;
            align-items: center;

            b {
              margin-left: 8px;
              font-size: 16px;
              font-weight: 500;
              line-height: 1;
            }
            b.primary-111 { color: $primary; }
            b.black-111 { color: $black; }
          }
          span {
            margin-top: 10px;
            font-size: 12px;
            font-weight: 400;
            color: $da;
          }
        }
      }
    }

    .box-20 {
      flex: 1;
      padding: 40px 0 20px 30px;

      .box-21 {
        width: 340px;
        height: 66px;
        border-radius: 5px;
        overflow: hidden;
      }
      .box-22 {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 340px;
        height: 230px;
        padding: 10px 20px 0 20px;
        margin-top: 20px;
        border-radius: 5px;
        border: 3px solid;
        border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(243, 245, 248, 1)) 3 3;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.07);
        background: linear-gradient(180deg, #F3F5F8 0%, #FFFFFF 100%);

        .title-1122 {
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 500;
          color: $c55;
        }
        .img-11220 {
          position: relative;
          width: 300px;
          height: 168px;
          cursor: pointer;
        }
        .img-1122 {
          width: 100%;
          height: 100%;
        }
        svg {
          position: absolute;
          bottom: 20px;
          left: 20px;
        }
      }
    }
  }
}
