@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.effect-tab-warp {
  display: inline-flex;
  justify-content: center;
  background-color: $f7;
  border-radius: 5px;
  height: 30px;
  align-items: center;
  b {
    display: block;
    padding: 0 15px;
    font-weight: 500;
    color: $black;
    font-size: 12px;
    line-height: 30px;
    cursor: pointer;
  }
  .active-tab {
    background-color: $primary;
    color: $white;
    border-radius: 5px;
  }
}
