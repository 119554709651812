@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.edit-modal-11 {
  @include white-bg(1);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 450px;
  padding: 20px 15px 30px 15px;
  border-radius: 5px;

  .line-01 {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    strong {
      font-size: 14px;
      font-weight: 600;
    }
  }
  .line-03 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .section-001 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 30px;

    .text-01 {
      margin-bottom: 10px;
      color: $c87;
    }
  }
}

.pop-555 {
  @include white-bg(1);
  display: flex;
  flex-direction: column;
  width: 370px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
