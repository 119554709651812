@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.pdf-check-model {
  background: $white;
  width: 900px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 20px 20px 0 20px;
  height: 700px;
  display: flex;
  flex-direction: column;
  .pdf-check-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $black;
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $ec;
    height: 43px;
    .close {
      cursor: pointer;
    }
  }
  .pdf-check-mid {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    .pdf-check-title {
      display: flex;
      margin-top: 20px;
      align-items: center;
      span {
        margin-left: 10px;
        font-weight: 500;
        color: $black;
      }
    }
    .pdf-model-one {
      margin-left: 6px;
      margin-top: 10px;
      border-left: 1px solid $ec;
      .check-item-time {
        display: flex;
        justify-content: space-between;
        background-color: $f7;
        border-radius: 3px;
        height: 38px;
        color: $primary;
        .left {
          display: flex;
          align-items: center;
        }
        .tab-right {
          display: flex;
          justify-content: space-between;
        }
        .tab-time-warp-list {
          height: 26px;
          margin-left: 20px;
          .tab-item {
            height: 20px;
            padding: 1px 20px;
            border-radius: 3px;
          }
        }
      }

      .pdf-model-two {
        border-left: 1px solid $ec;
        margin-left: 18px;
      }
    }

    .check-item {
      display: flex;
      align-items: center;
      padding: 8px 23px 6px 2px;
      margin-bottom: 2px;
      margin-left: 10px;
      height: 38px;
      cursor: pointer;
      span {
        padding: 0;
        margin-right: 10px;
      }
    }
  }

  .check-bot {
    width: 860px;
    border-top: 1px solid $ec;
    margin: 0 auto;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .check-bot-left {
      width: 100px;
      height: 36px;
      border-radius: 5px;
      border: 1px solid $cf;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      color: $black;
      cursor: pointer;
      span {
        margin-left: 5px;
      }
    }
    .check-bot-right {
      display: flex;
      justify-content: flex-start;
      .cancel {
        width: 80px;
        height: 36px;
        border-radius: 5px;
        border: 1px solid $cf;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        color: $black;
        cursor: pointer;
      }
      .ecport {
        width: 100px;
        height: 36px;
        background: $secondary;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        color: $white;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}
