@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

* {
  margin: 0;
  padding: 0;
}

.index-pdf-bg {
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  // overflow-y: auto;
  // background-color: $white;

  .index-pdf-warp {
    width: 595px;
    display: flex;
    flex-direction: column;

    .top-btn {
      @include white-bg(1);
      border-bottom: 1px solid $ec;
      display: flex;
      justify-content: space-between;
      position: fixed;
      z-index: 100;
      padding: 10px 10px 9px;
      font-size: 14px;
      width: 595px;
      align-items: center;
      a {
        cursor: pointer;
        color: $c55;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.pdf-page-static-top {
  width: 595px;
  height: 843px;
  background-color: $f7;
  padding: 50px;
  overflow: hidden;
}

.pdf-page-static {
  width: 595px;
  height: 843px;
  background-color: $f7;
  padding: 50px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  .water-img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}

.static-detail-page {
  width: 595px;
  height: 843px;
  margin-top: 20px;
  overflow: hidden;
  position: relative;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .uuid {
    position: absolute;
    right: 50px;
    top: 190px;
    color: #999;
    transform: scale(0.6,1);
    transform-origin: right;
  }
  .water-img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .index-pdf-head {
    position: absolute;
    left: 40px;
    top: 24px;
  }

  .static-foot {
    width: calc(100% - 80px);
    position: absolute;
    left: 40px;
    bottom: 24px;
  }
}

.static-bg {
  background-image: url("https://file.smartsalary.cn/index-pdf/02.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 100% 100%;
  .uuid {
    position: absolute;
    right: 50px;
    top: 210px;
    color: #999;
    transform: scale(0.6,1);
    transform-origin: right;
  }
}
