@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.city-recruitment-warp {
  .tops-title-warp {
    display: flex;
    justify-content: space-between;
    .wealfare-title-111 {
      width: 100%;
    }
  }
  .city-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    margin-top: 20px;
    border-radius: 8px;
    .city-head-content {
      display: flex;
      justify-content: space-between;
      .right-con {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          display: flex;
          justify-content: space-between;
          border: 1px solid $ec;
          border-radius: 4px;
          padding: 5px;
          align-items: center;
          margin-left: 5px;
          cursor: pointer;
          span {
            background-color: $primary;
            color: $fff;
            border-radius: 5px;
            margin-right: 5px;
            padding: 0 5px;
          }
        }
      }
    }
    .city-content {
      .table {
        display: flex;
        justify-content: space-between;
        .right {
          width: 350px;
          margin-left: 10px;
          background-color: $fff;
          border-radius: 8px;
          padding: 10px;
          p {
            line-height: 25px;
            font-size: 14px;
            color: $c55;
            &:nth-child(1) {
              line-height: auto;
              font-weight: 600;
            }
          }
        }
        .city-svg-warp {
          width: calc(100% - 360px);
          border-radius: 8px;
          background-color: $fff;
          padding: 15px;
          .city-svg {
            height: 350px;
            width: 100%;
            overflow-x: auto;
          }
          .detail {
            @include black-color(0.85);
            font-size: 12px;
            padding: 0 25px;
            span {
              margin-right: 20px;
            }
            i {
              @include mont-font();
              color: $primary;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .city-tab {
    margin: 20px 0;
  }
}

.model-cityList-warp {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .model-content-warp {
    width: 520px;
    background-color: $fff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .model-top {
      display: flex;
      justify-content: space-between;
      height: 60px;
      align-items: center;
      padding: 0 20px;
      .model-title {
        font-size: 16px;
        color: $c333;
        font-weight: 500;
        border-top: 2px solid $secondary;
      }
    }
    .model-content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      overflow: auto;
      height: 300px;
      border-bottom: 1px solid $ec;
      border-top: 1px solid $ec;
      .model-cityList {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .items {
          border-radius: 3px;
          color: $black;
          font-size: 14px;
          padding: 10px 20px;
          margin-top: 3px;
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-left: 3px;
        }
        .active {
          @include dark-bg(0.1);
          margin-top: 3px;
          border-radius: 3px;
          color: $primary;
          font-size: 14px;
          padding: 10px 20px;
          margin-top: 3px;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-weight: 600;
          margin-left: 3px;
        }
      }
    }
    .sure-warp {
      display: flex;
      justify-content: flex-end;
      margin: 20px;
      .sure {
        width: 80px;
        height: 36px;
        font-size: 14px;
        background-color: $secondary;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $fff;
        cursor: pointer;
      }
    }
  }
}
