@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.income-fee-rate_10 {
  .content-1013 {
    display: flex;
    flex-direction: column;
  }
  .content-0011 {
    width: 100%;
    display: flex;
  }
}
