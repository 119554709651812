@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.indicators-warp {
  padding: 20px;
  border-radius: 8px;
  background: $ec;
  margin-top: 8px;
  .indicator-title {
    font-size: 14px;
    font-weight: 500;
    color: $black;
  }
  .indicator-content {
    margin-top: 10px;
    margin-bottom: 10px;
    .indicator-content-item {
      line-height: 35px;
      font-weight: 500;
      color: $c55;
    }
  }
  .indicator-rule {
    color: $black;
    font-weight: 500;
    margin-bottom: 20px;
    display: block;
  }
  .indicator-close {
    font-weight: 600;
    color: $primary;
    cursor: pointer;
  }
}
