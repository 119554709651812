@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.effect-com-sub-warp {
  .top-detail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-size: 20px;
      font-weight: 600;
      color: $black;
    }
    .sub-title {
      margin: 0 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      border-radius: 3px;
      padding: 0 3px;
    }
    .sub-tab {
      background-color: $f7;
      border-radius: 3px;
      display: flex;
      justify-content: flex-start;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 20px;
        cursor: pointer;
        border-radius: 3px;
      }
      .active-tab {
        background-color: $primary;
      }
    }
  }
  .detail {
    font-size: 12px;
    font-weight: 500;
    color: $c55;
    margin-top: 5px;
  }
}
