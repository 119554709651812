@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.effect-pdf-page {
  width: 750px;
  margin: 0 auto;
  background-color: $primary;
  min-height: 100vh;
  padding: 30px 20px 20px;
  .pdf-items-warp {
    background-color: $white;
    border-radius: 20px;
    padding: 20px 30px;
    margin-top: 10px;
  }
  .pdf-sub-title-warp {
    margin-top: 30px;
    margin-bottom: 15px;
    justify-content: center;
    display: flex;
    .pdf-sub-title {
      font-size: 16px;
      font-weight: 600;
      position: relative;
      display: inline-block;
      &::after {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        height: 5px;
        bottom: 4px;
        left: 0;
        opacity: 0.5;
        background-color: $secondary;
      }
    }
  }
  .top-pdf-content {
    padding: 0 10px;
    .logo-11 {
      @include white-color(1);
      width: 80px;
      margin-bottom: 40px;
    }
    .detail {
      padding-bottom: 30px;
      padding-top: 58px;
      margin-bottom: 30px;
      background-image: url("https://file.smartsalary.cn/web-img%402.0/home/pic111.png");
      background-position: right center;
      background-repeat: no-repeat;
      .top {
        @include white-bottom-boder(0.6);
        display: inline-block;
        font-size: 36px;
        color: $white;
        padding-bottom: 15px;
        span {
          background-color: $secondary;
        }
      }
      .mid {
        @include mont-font();
        font-size: 24px;
        color: $white;
        margin-top: 15px;
      }
      .bot {
        font-size: 16px;
        color: $secondary;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;
        span {
          display: block;
          width: 10px;
          height: 10px;
          background-color: $secondary;
          transform: rotate(45deg);
          margin-right: 10px;
        }
      }
    }
  }
  .effect-pdf-foot {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    .left {
      width: 392px;
      p {
        color: $white;
        font-size: 36px;
        font-weight: 600;
        &:nth-child(4) {
          font-size: 14px;
          line-height: 30px;
          margin-top: 30px;
        }
      }
      .line {
        display: block;
        width: 216px;
        height: 1px;
        background-color: $white;
        margin: 15px 0;
        opacity: 0.6;
      }
      .logo-11 {
        margin-top: 60px;
        color: $white;
        float: right;
        width: 80px;
      }
    }
    .code {
      width: 240px;
      height: 240px;
      border-radius: 10px;
      padding: 15px;
      background-color: $white;
    }
  }
}

.box-5550 {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.lead-box_555 {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  border-radius: 0 0 20px 20px;
  background-color: $fff;
}
