@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.effect-time-warp-pdf {
  .chart-demo {
    display: flex;
    margin-top: 30px;
  }
  .industry-chart-warp-pdf {
    width: 100%;
    height: 400px;
  }
}
