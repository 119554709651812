@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.title-pdf-top {
  width: auto;
  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    color: $black;
    span {
      display: block;
      width: 100px;
      height: 1px;
      background-color: $ec;
    }
    i {
      display: block;
      width: 6px;
      height: 6px;
      background-color: $ec;
      transform: rotate(45deg);
      margin: 0 10px;
    }
  }
  .bot {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    span {
      display: block;
      margin-right: 10px;
      padding: 1px 3px;
      border-radius: 3px;
    }
    i {
      @include primary-bg(0.1);
      display: block;
      padding: 1px 3px;
      color: $primary;
      border-radius: 3px;
    }
  }
}
