@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.adress-page {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;
  p {
    margin-left: 20px;
    font-size: 12px;
    font-family: Helvetica;
    color: #000000;
    line-height: 12px;
    margin-top: 16px;
  }
}
