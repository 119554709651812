@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.effect-time-tab-warp {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 16px;
    border-radius: 8px;
    background-color: $f7;
    .top-item {
      width: 40px;
      display: flex;
      justify-content: center;
      &:last-child {
        display: flex;
        justify-content: right;
      }
      &:first-child {
        display: flex;
        justify-content: left;
      }
    }
    b {
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $c55;
      opacity: 0.2;
      cursor: pointer;
    }
    .active-tab {
      width: 18px;
      height: 18px;
      border: 2px solid $white;
      background-color: $primary;
      opacity: 1;
      box-shadow: 0 0 4px 0 rgba(52, 63, 153, 0.4);
    }
  }

  .bot {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    b {
      @include mont-font();
      color: $c55;
      font-weight: 600;
      cursor: pointer;
      width: 40px;
      text-align: center;
      &:last-child {
        text-align: right;
      }
      &:first-child {
        text-align: left;
      }
    }
    .active-tab {
      color: $primary;
    }
  }
}
