@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.video-1155 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .video-1166 {
    position: relative;
    width: 1000px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: $white;
    border-radius: 10px;

    .close-111 {
      position: absolute;
      right: -30px;
      top: 0px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 1;
    }
    .small-img {
      width: 200px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      position: absolute;
      right: 0;
      top: 0px;
      background-color: $white;
      border-radius: 10px;
      overflow-y: auto;
      height: 100%;
      .item {
        width: 100%;
        height: 800px;
        padding: 10px;
        margin-top: 20px;
        cursor: pointer;
        display: flex;
        height: 50px;
        justify-content: space-between;
        p {
          font-size: 12px;
          span {
            color: $c55;
          }
        }
        img {
          height: 50px;
          margin-right: 5px;
          border-radius: 3px;
        }
      }
    }
  }
}
