@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.common-table-warp {
  border-radius: 5px;
  margin-top: 10px;
  overflow: hidden;
  .table-title {
    display: flex;
    justify-content: space-between;
    background-color: $f7;
    line-height: 36px;
    span {
      text-align: center;
      &:first-child {
        text-align: left;
        text-indent: 10px;
      }
      &:last-child {
        text-align: right;
        padding-right: 10px;
      }
    }
  }
  .table-content {
    max-height: 360px;
    overflow-y: auto;
    p {
      @include mont-font();
      display: flex;
      justify-content: space-between;
      background-color: $f7;
      line-height: 36px;
      align-items: center;
      &:nth-child(odd) {
        background-color: $white;
      }
      span {
        @include mont-font();
        text-align: center;
        &:first-child {
          text-align: left;
          text-indent: 10px;
        }
        &:last-child {
          text-align: right;
          padding-right: 10px;
        }
      }
    }
  }
}
