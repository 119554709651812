@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.salary-011 {
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  .title-1155 {
    display: inline-flex;
    align-items: flex-end;
  }

  .salary-015 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .salary-021 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 17px;

    .section-0055 {
      display: flex;
      justify-content: space-between;
    }

    .section-1155 {
      @include white-bg(1);
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      border-radius: 5px;

      .line-101 {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 10px 10px 20px;
        border-bottom: 1px solid $ec;

        span {
          padding-bottom: 5px;
          font-size: 16px;
          font-weight: 600;

          b { color: $cf; }
        }
        span.click-111 {
          position: relative;
          cursor: pointer;

          i {
            position: absolute;
            bottom: 0;
            left: 0;
            display: inline-flex;
            width: 30px;
            height: 4px;
            border-radius: 2px;
            background-color: $secondary;
          }
        }
        .inactive-011 {
          color: $c87;

          i { visibility: hidden; }
        }
      }
      .line-103 {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        padding: 0 10px 0 20px;
        border-bottom: 1px solid $ec;

        .line-10301 {
          display: inline-flex;
          align-items: center;

          span {
            display: inline-flex;
            align-items: flex-end;
            line-height: 16px;
            font-size: 12px;
            font-weight: 500;
            color: $c55;

            svg {
              position: relative;
              margin-right: 4px;
            }
          }
        }
      }

      .section-199 {
        margin: 20px;
        display: flex;
        flex-direction: column;
        border-top: 1px solid $ec;
        border-left: 1px solid $ec;
        border-right: 1px solid $ec;
        border-radius: 5px;

        .header-199 {
          width: 100%;
          display: inline-flex;

          p {
            height: 60px;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            border-bottom: 1px solid $ec;
            color: $c55;

            span, i {
              font-size: 12px;
              color: $c55;
            }
            span {
              flex: 1;
              width: 100%;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              &.span-111 { border-bottom: 1px solid $ec; }
            }
            i {
              flex: 1;
              height: 100%;
              display: inline-flex;
              justify-content: center;
              align-items: center;

              // &.i-111 { border-right: 1px solid $ec; }
            }
            &.line-001 {
              flex: 1;
              border-right: 1px solid $ec;
            }
            &.line-003 {
              width: 100px;
            }
          }
        }

        .line-199 {
          display: inline-flex;
          height: 80px;
          border-bottom: 1px solid $ec;

          .line-200 {
            flex: 1;
            display: inline-flex;
            align-items: center;
            border-right: 1px solid $ec;
            cursor: pointer;

            .line-200255 {
              flex: 1;
              display: inline-flex;
              flex-direction: column;
              align-items: center;

              b {
                font-size: 12px;
                font-weight: 600;
                color: $c87;
              }
              span {
                font-size: 12px;
                color: $cf;
              }
            }
          }
          .line-201 {
            width: 100px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
          .line-2001 {
            display: inline-flex;
            flex-direction: column;
            margin: 0 10px 0 28px;

            .line-20011 {
              display: inline-flex;

              strong {
                font-size: 14px;
                font-weight: 600;
                line-height: 1;
                white-space: nowrap;
                cursor: pointer;

                &:hover, &.hover { color: $primary; }
              }
              b {
                margin-left: 10px;
                padding: 2px 5px;
                font-size: 12px;
                font-weight: 500;
                border-radius: 3px;
                line-height: 1;
                white-space: nowrap;
                cursor: pointer;
              }
              b.down {
                @include success-bg(0.1);
                color: $success;
              }
              b.up {
                @include error-bg(0.1);
                color: $error;
              }
            }
            .line-20013 {
              display: inline-flex;
              align-items: center;
              margin-top: 10px;

              b {
                margin-right: 10px;
                padding: 2px 5px;
                font-size: 12px;
                font-weight: 500;
                border-radius: 3px;
                line-height: 1;
              }
              .b-01 {
                color: #86D4E0;
                background-color: rgba(134, 212, 224, 0.1);
              }
              .text-00 {
                font-size: 12px;
                line-height: 1;
                color: #9DA2B0;
              }
            }
          }
          .line-2002 {
            flex: 1;
            display: inline-flex;
            margin: 0 8px;

            .b-00 {
              font-size: 12px;
              font-weight: 600;
              line-height: 1;
              color: $c87;
            }
            .line-20021 {
              flex: 1;
              position: relative;
              display: inline-flex;
              align-items: center;
              margin: 0 5px;

              span {
                position: absolute;
                left: 0;
                width: 100%;
                height: 10px;
                border-radius: 2px;
                background: linear-gradient(90deg, #FCD577 0%, #F8AB42 100%);
              }
              i {
                position: absolute;
                display: inline-flex;
                width: 4px;
                height: 20px;
                z-index: 100;
                border: 1px solid $fff;
                background-color: $secondary;
              }
              b {
                position: relative;
                bottom: 15px;
                font-size: 12px;
                line-height: 1;
                font-weight: 600;
                z-index: 111;
                color: $secondary;
              }
            }
          }
          .line-2003 {
            flex: 1;
            display: inline-flex;
            // margin: 0 10px 0 30px;

            .p-00 {
              flex: 1;
              display: inline-flex;
              align-items: center;
              justify-content: center;

              b {
                margin-left: 2px;
                font-size: 12px;
                line-height: 1;
                font-weight: 600;
                color: $c55;
              }
            }
            .p-01 { justify-content: flex-end; }
            .line-20025 {
              width: 80px;
              position: relative;
              display: inline-flex;
              align-items: center;
              margin: 0 10px;

              span {
                position: absolute;
                left: 0;
                width: 80px;
                height: 10px;
                border-radius: 2px;
                background: linear-gradient(90deg, #F46868 0%, #E02020 100%);
              }
              i {
                position: absolute;
                display: inline-flex;
                width: 4px;
                height: 20px;
                z-index: 100;
                border: 1px solid $fff;
                background-color: $error;
              }
              b {
                position: relative;
                bottom: 15px;
                font-size: 12px;
                line-height: 1;
                font-weight: 600;
                z-index: 111;
                color: $error;
              }
            }
          }
        }

      }
    }
  }
}
