@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.page-1101 {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  background-color: $f7;

  .login-100 {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px 60px 40px 30px;
    overflow-y: auto;

    .line-11019 {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 80px;
        cursor: pointer;
      }
    }
    .line-11021 {
      @include c55-color(0.5);
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 20px;
      font-size: 12px;
      font-weight: 500;
    }

    .login-55 {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .login-150 {
        @include white-bg(1);
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
      }

      .line-1110 {
        display: inline-flex;
        align-items: center;
        margin-top: 20px;

        span {
          font-size: 12px;
        }
      }
    }
  }
}

.company-pop_111 {
  @include white-bg(1);
  display: flex;
  flex-direction: column;
  width: 360px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}
