@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.dash-industry-warp {
  // max-width: 1840px;
  .title {
    padding: 30px 0 0 0;
  }
  .dash-effect-warp {
    position: relative;
    margin-top: 20px;
    background: $fff;
    border-radius: 5px;
    cursor: pointer;
    .position-title {
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      color: $black;
      opacity: 0.1;
      line-height: 56px;
      width: 100%;
      z-index: 0;
    }
    .dash-industry-content {
      // height: 218px;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      h3 {
        font-size: 18px;
        font-weight: 600;
        color: $black;
      }
      .contents {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        flex: 1;
        .top {
          font-size: 18px;
          font-weight: 600;
          color: $black;
        }
        h4 {
          font-weight: 400;
          color: $c55;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            background-color: $error;
            color: $white;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            border-radius: 3px;
            padding: 0 2px;
            margin-left: 2px;
          }
        }
        .left {
          width: 20%;
          padding: 10px;
          text-align: center;
          cursor: pointer;
          // span {
          //   font-size: 12px;
          //   color: $c55;
          // }
          .empty-101 img{
            width: 90px;
            height: auto;
          }
          .talent-chart-warp {
            width: 140px;
            height: 90px;
            margin: 0 auto;
            position: relative;
            .talent-chart {
              width: 140px;
              height: 90px;
              canvas {
                cursor: pointer;
              }
            }
            p {
              position: absolute;
              left: -20px;
              bottom: 0;
              width: 180px;
              display: flex;
              justify-content: space-between;
              span {
                color: $c55;
                font-size: 12px;
              }
            }
          }
          .effect-chart {
            width: 100%;
            height: 100px;
            cursor: pointer;
            canvas {
              cursor: pointer;
            }
          }
          .power-chart {
            width: 100%;
            height: 100px;
            canvas {
              cursor: pointer;
            }
          }
        }
        .special {
          width: 20%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          padding: 10px;
          border-left: 1px solid $ec;
          cursor: pointer;
          .empty-101 img{
            width: 90px;
            height: auto;
          }
          h4 {
            margin-bottom: 5px;
          }
          .type-items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width: 200px;
            span {
              margin-top: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background: $f7;
              border-radius: 5px;
              border: 1px solid $ec;
              padding: 0 3px;
              display: flex;
              width: 90px;
              justify-content: space-between;
              font-size: 12px;
              i {
                @include mont-font();
                color: $error;
              }
              label {
                cursor: pointer;
              }
            }
            .item {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }
        }
        .mid {
          width: 20%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          padding: 10px;
          border-left: 1px solid $ec;
          cursor: pointer;
          b {
            @include mont-font();
            font-weight: 600;
            color: $black;
            font-size: 24px;
            cursor: pointer;
            margin-top: 10px;
          }
          .change-radio {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 30px;
            a {
              color: $c55;
              margin-right: 5px;
            }
            i {
              @include mont-font();
            }
          }
          .citys {
            margin-top: 30px;
            a {
              color: $c55;
              margin-right: 5px;
            }
            span {
              color: $black;
              font-weight: 600;
            }
          }
        }
        .right {
          width: 20%;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 10px;
          border-left: 1px solid $ec;
          cursor: pointer;
          .empty-101 img{
            width: 90px;
            height: auto;
          }
        }
      }
    }
  }
  .banner-group {
    display: flex;
    justify-content: space-between;
    .item {
      width: calc(33.3333% - 20px);
      background: url(https://file.smartsalary.cn/web-img/home/banner002.png);
      background-size: cover;
      border-radius: 8px;
      padding: 10px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      &:nth-child(1) {
        background: url(https://file.smartsalary.cn/web-img/home/banner001.png);
      background-size: cover;
      }
      &:nth-child(3) {
        background: url(https://file.smartsalary.cn/web-img/home/banner003.png);
      background-size: cover;
      }
      h4 {
        font-weight: 600;
        color: $white;
        font-size: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        span {
          background-color: $error;
          color: $white;
          font-size: 12px;
          font-weight: 600;
          line-height: 16px;
          border-radius: 3px;
          padding: 0 2px;
          margin-left: 5px;
        }
      }
      p {
        font-weight: 600;
        color: $white;
        font-size: 12px;
        margin-top: 13px;
      }
      a {
        width: 78px;
        height: 20px;
        background: $white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        font-size: 12px;
        display: flex;
        padding: 0 5px;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        cursor: pointer;
      }
    }
  }
}
