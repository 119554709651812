@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.universal-year-table {
  @include black-bg(0.02);
  // width: calc(100% - 60px);
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  justify-content: flex-start;
  border-radius: 8px;
  .universal-year-table-left {
    width: calc(50% - 30px);
    white-space: nowrap;
    p {
      @include black-color(0.85);
      font-size: 14px;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      line-height: 32px;
      text-align: center;
      margin-left: 20px;
    }
    p:nth-last-child(1) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
  .universal-year-table-right {
    margin-left: 20px;
    width: calc(50% - 30px);
    p {
      @include mont-font;
      @include black-color(0.85);
      font-size: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      line-height: 32px;
      text-align: right;
      margin-left: 20px;
      white-space: nowrap;
    }
    section:nth-child(1) {
      p {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        font-family: "PingFang SC", "PingFang SC Medium", "PingFangSC Regular", system-ui, sans-serif;
      }
    }
  }
  .switch-year-table {
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    // height: calc(100% - 32px);
    margin-top: 33px;
    display: flex;
    text-align: center;
    align-items: center;
    p {
      text-align: center;
      width: 100%;
    }
  }
}
