@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.hotrole-recruitment-warp {
  .tops-title-warp {
    display: flex;
    justify-content: space-between;
    .wealfare-title-111 {
      width: 100%;
    }
  }
  .quantity-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    margin-top: 20px;
    border-radius: 8px;
    position: relative;
    .hot-role-change {
      position: absolute;
      right: 0;
      top: 20px;
      display: flex;
      justify-content: flex-start;
      .items {
        margin-right: 20px;
      }
    }
    .quantity-content {
      margin-top: 20px;

      .table {
        display: flex;
        justify-content: space-between;
        .quantity-svg-warp {
          width: 100%;
          background: $fff;
          border-radius: 5px;
          padding: 10px;
          .chart-check {
            font-size: 14px;
            font-weight: 600;
            color: $black;
            text-align: center;
            margin-left: 59px;
          }
          .chart-top {
            display: flex;
            justify-content: flex-start;
            margin-top: 8px;
            .chart-left-title {
              width: 14px;
              color: $black;
              font-weight: 600;
              display: flex;
              align-items: center;
              span {
                color: $da;
              }
            }
            .quantity-chart {
              height: 396px;
              width: 100%;
            }
          }
          .chart-bot-num {
            margin-left: 50px;
            position: relative;
            width: calc(100% - 50px);
            height: 20px;
            span {
              @include mont-font();
              @include black-color(0.85);
              display: block;
              width: 20px;
              font-size: 12px;
              &:nth-child(1) {
                position: absolute;
                left: 0;
                top: 5px;
              }
              &:nth-child(2) {
                position: absolute;
                top: 5px;
                left: 20%;
                margin-left: -10px;
              }
              &:nth-child(3) {
                position: absolute;
                left: 40%;
                top: 5px;
                margin-left: -10px;
              }
              &:nth-child(4) {
                position: absolute;
                top: 5px;
                left: 60%;
                margin-left: -10px;
              }
              &:nth-child(5) {
                position: absolute;
                left: 80%;
                top: 5px;
                margin-left: -10px;
              }
              &:nth-child(6) {
                position: absolute;
                right: 0;
                top: 5px;
                margin-right: -5px;
              }
            }
          }
          .chart-bot {
            text-align: center;
            margin-top: 10px;
            font-weight: 600;
            color: $black;
            span {
              color: $da;
            }
          }
        }
      }
    }
  }
  .heat-warp {
    background-color: $f7;
    padding: 20px 10px 10px;
    margin-top: 20px;
    border-radius: 8px;
    .heat-content {
      margin-top: 20px;
      .table {
        display: flex;
        justify-content: space-between;
        .heat-left-svg-warp {
          width: 350px;
          height: 434px;
          background: $fff;
          border-radius: 5px;
          padding: 10px;
          .main-title {
            font-weight: 600;
            color: $black;
            text-align: center;
          }
          .sub-title {
            font-weight: 400;
            color: $c87;
            text-align: center;
            margin-top: 10px;
          }
          .items {
            display: flex;
            justify-content: space-between;
            margin-top: 17px;
            align-items: center;
            span {
              display: block;
              width: 100px;
              font-size: 12px;
              color: $c55;
            }
            p {
              flex: 1;
              margin: 0 10px;
              i {
                display: block;
                height: 16px;
                border-radius: 2px;
              }
            }
            b {
              @include mont-font();
              display: block;
              width: 20px;
              font-size: 13px;
            }
          }
        }
        .heat-right-svg-warp {
          margin-left: 10px;
          flex: 1;
          height: 434px;
          background: $fff;
          border-radius: 5px;
          padding: 10px;
          .chart-check {
            font-size: 14px;
            font-weight: 600;
            color: $black;
            text-align: center;
            margin-left: 59px;
          }
          .chart-top {
            display: flex;
            justify-content: flex-start;
            margin-top: 8px;
            .chart-left-title {
              width: 14px;
              color: $black;
              font-weight: 600;
              display: flex;
              align-items: center;
              span {
                color: $da;
              }
            }
            .chart-left-num {
              width: 20px;
              position: relative;
              text-align: right;
              margin-left: 10px;
              span {
                @include mont-font();
                @include black-color(0.5);
                display: block;
                width: 20px;
                font-size: 12px;
                &:nth-child(1) {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                }
                &:nth-child(2) {
                  position: absolute;
                  left: 0;
                  bottom: 20%;
                  margin-bottom: -9px;
                }
                &:nth-child(3) {
                  position: absolute;
                  left: 0;
                  bottom: 40%;
                  margin-bottom: -9px;
                }
                &:nth-child(4) {
                  position: absolute;
                  left: 0;
                  bottom: 60%;
                  margin-bottom: -9px;
                }
                &:nth-child(5) {
                  position: absolute;
                  left: 0;
                  bottom: 80%;
                  margin-bottom: -9px;
                }
                &:nth-child(6) {
                  position: absolute;
                  left: 0;
                  top: 0;
                  margin-top: -5px;
                }
              }
            }
            .chart-content {
              flex: 1;
              height: 336px;
              background-image: url(https://file.smartsalary.cn/web-img%402.0/home/1099.png);
              background-size: 100% 100%;
              background-repeat: no-repeat;
              margin-left: 5px;
              position: relative;
              border-radius: 5px;
              b {
                box-shadow: 0 0 5px 0 rgba(224, 32, 32, 0.2);
                border-radius: 2px;
                background-color: $white;
                height: 20px;
                line-height: 20px;
                text-align: center;
                position: absolute;
                left: 3%;
                top: 12px;
                width: 50px;
                &:nth-child(2) {
                  color: $error;
                }
                &:nth-child(3) {
                  color: $secondary;
                  left: 33%;
                }
                &:nth-child(4) {
                  color: $success;
                  left: 73%;
                }
              }
              .item-pointer {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: $black;
                font-size: 12px;
                flex-wrap: nowrap;
                width: auto;
                margin-left: -6px;
                margin-bottom: -6px;
                cursor: pointer;
                position: relative;
                .bg-title {
                  @include black-bg(0.6);
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  border-radius: 12px;
                  padding: 0 5px;
                  flex-wrap: nowrap;
                  width: auto;
                  i {
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    @include white-bg(0.8);
                    cursor: pointer;
                    margin-right: 5px;
                  }
                  u {
                    color: $white;
                    text-decoration: none;
                    font-size: 10px;
                    font-weight: normal;
                  }
                }
                .details {
                  background-color: $white;
                  border-radius: 8px;
                  padding: 15px;
                  position: absolute;
                  width: 180px;
                  top: 26px;
                  left: -80px;
                  border: 1px solid $ec;
                  z-index: 99;
                  display: block;
                  span {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 3px;
                    align-items: center;
                    a {
                      display: flex;
                      justify-content: flex-start;
                      u {
                        color: $black;
                        display: flex;
                        width: 8px;
                        height: 8px;
                        border-radius: 8px;
                        -webkit-border-radius: 8px;
                        -moz-border-radius: 8px;
                        -ms-border-radius: 8px;
                        -o-border-radius: 8px;
                        margin-right: 5px;
                        margin-top: 5px;
                      }
                    }
                    em {
                      @include mont-font();
                      font-style: normal;
                      font-size: 14px;
                      font-weight: 500;
                    }
                  }
                  .titles-one {
                    @include black-color(0.85);
                    a {
                      font-size: 14px;
                      font-weight: 500;
                      margin-bottom: 8px;
                    }
                  }
                }
              }
              .show-table {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                position: absolute;
                left: 0;
                top: 0;
                background-color: transparent;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                span {
                  display: block;
                  width: 20%;
                  height: 20%;
                  border: 2px solid $f3f4;
                  border-radius: 5px;
                }
              }
            }
          }
          .chart-bot-num {
            margin-left: 50px;
            position: relative;
            width: calc(100% - 50px);
            height: 20px;
            span {
              @include mont-font();
              @include black-color(0.85);
              display: block;
              width: 20px;
              font-size: 12px;
              &:nth-child(1) {
                position: absolute;
                left: 0;
                top: 5px;
              }
              &:nth-child(2) {
                position: absolute;
                top: 5px;
                left: 20%;
                margin-left: -10px;
              }
              &:nth-child(3) {
                position: absolute;
                left: 40%;
                top: 5px;
                margin-left: -10px;
              }
              &:nth-child(4) {
                position: absolute;
                top: 5px;
                left: 60%;
                margin-left: -10px;
              }
              &:nth-child(5) {
                position: absolute;
                left: 80%;
                top: 5px;
                margin-left: -10px;
              }
              &:nth-child(6) {
                position: absolute;
                right: 0;
                top: 5px;
                margin-right: -5px;
              }
            }
          }
          .chart-bot {
            text-align: center;
            margin-top: 10px;
            font-weight: 600;
            color: $black;
            span {
              color: $da;
            }
          }
        }
      }
    }
  }
}
