@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.login-155 {
  display: flex;
  flex-direction: column;

  .line-001 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 20px;
    background-color: $f7;

    span {
      font-weight: 500;
      font-size: 14px;
      color: #6980FF;
    }
    b {
      display: inline-flex;
      align-items: center;
      height: 18px;
      padding: 0 5px;
      margin: 0 5px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      border-radius: 5px;
      color: $fff;
      background: linear-gradient(231deg, #6980FF 0%, #936BEC 100%);
    }
  }

  strong {
    margin-top: 20px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    color: $black;
  }
  .line-010 {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;

    p {
      display: inline-flex;
      align-items: center;
      span {
        line-height: 1;
        color: $c55;
      }
    }
    i {
      font-size: 12px;
      color: $c55;
    }
  }
  .line-011 {
    display: inline-flex;
    margin-top: 30px;
  }
  .line-012 {
    display: inline-flex;
    flex-direction: column;

    .line-115 {
      position: relative;
      display: inline-flex;
      flex-direction: column;
    }
    .line-116 { margin-top: 20px; }
    .line-117 { margin-top: 2px; }
    .line-0011 {
      position: relative;
      display: inline-flex;
      flex-direction: column;
    }
    .err-11 {
      padding-left: 6px;
      font-size: 12px;
      color: $error;
    }
    .err-hidden { visibility: hidden; }
    .err-visible { visibility: visible; }
    .line-0015 {
      display: inline-flex;
      align-items: center;
      margin-top: 10px;
      margin-left: 10px;

      .text-11 {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        color: $c55;
      }
    }
  }
  .line-013 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 34px;
    margin-top: 20px;
    margin-bottom: 20px;

    i {
      font-size: 14px;
      line-height: 1;
      color: $c55;
    }
  }
  .line-015 {
    display: inline-flex;
    flex-direction: column;
    margin-top: 2px;

    .line-0151 {
      display: inline-flex;
      align-items: center;

      i {
        display: inline-flex;
        height: 1px;
        flex: 1;
        background-color: $ec;
      }
      span {
        padding: 0 10px;
        font-size: 14px;
        font-weight: 500;
        color: $d9;
      }
    }
    .line-0152 {
      display: inline-flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
  .line-016 {
    display: inline-flex;
    justify-content: center;
    margin-top: 20px;

    b {
      margin: 0 20px;
      color: $c55;
    }
  }
  .line-110011 {
    display: inline-flex;
    height: 1px;
    width: 100%;
    margin-top: 10px;
    background-color: $d9;
  }
}
