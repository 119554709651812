@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.tip-6600 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content-666000 {
    @include white-bg(1);
    position: relative;
    width: 440px;
    height: 250px;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

    .title-666 {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      width: 100%;
      border-bottom: 1px solid $ec;

      strong {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .text-666000 {
      flex: 1;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: $black;
      }
    }
    .line-666 {
      display: inline-flex;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }
}
