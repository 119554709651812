@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.index-page_10 {
  display: flex;
  flex-direction: column;
  padding-top: 60px;
  background-color: $fff;
  min-height: 100vh;

  .index-container_10 {
    position: relative;
    display: flex;
    justify-content: center;

    .container-top_10 {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 245px;
      z-index: 50;
      background-color: #181C25;
    }
    .index-content_10 {
      width: 100%;
      max-width: 1920px;
      min-width: 1280px;
      z-index: 100;

      .content_10 {
        display: flex;
      }
    }
  }
}
