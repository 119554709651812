@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.industry-11100 {
  @include white-bg(1);
  position: absolute;
  top: 55px;
  left: -60px;
  width: 520px;
  height: 400px;
  display: flex;
  flex-direction: column;
  z-index: 55;
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);

  .line-11 {
    width: 100%;
    height: 50px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    strong {
      font-size: 16px;
      font-weight: 500;
      color: $c333;
    }
  }

  .section-11 {
    flex: 1;
    display: flex;
    border-top: 1px solid $ec;
    overflow: hidden;

    .section-13 {
      display: inline-flex;
      flex-direction: column;
      overflow-y: auto;

      .line-31 {
        display: inline-flex;
        flex-direction: column;
      }
    }
    .section-15 {
      flex: 1;
      padding: 0 10px 10px 0;
    }
  }
}
