@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.welfare-tab-content-01 {
  height: 36px;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  border: 1px solid $bodyBg;
  border-radius: 4px;

  .tab-item {
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    background-color: $fff;
    border-radius: 4px;
  }

  .tab-item-active {
    width: 80px;
    height: 30px;
    background-color: $primary;
    color: $fff;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    border-radius: 4px;
  }
}
