@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.card-1100 {
  @include secondary-bg(0.07);
  display: flex;
  flex-direction: column;
  width: calc(25% - 15px);
  height: 140px;
  padding: 20px 16px 15px 16px;
  border-radius: 5px;

  .line-5500 {
    display: inline-flex;
    align-items: center;

    .bold-11 {
      @include secondary-color(1);
      line-height: 1;
      margin-right: 5px;
      font-size: 14px;
    }
    .span-11 {
      line-height: 1;
      font-size: 14px;
      color: $c55;
    }
    strong {
      font-size: 30px;
      line-height: 1;
      font-weight: 600;
    }
    .span-15 {
      margin-left: 5px;
      font-size: 12px;
      font-weight: 600;
      color: $c87;
    }
    .bold-13 {
      // line-height: 1;
      margin-left: 2px;
      font-size: 13px;
      font-weight: 600;
      color: $error;
    }
    .span-13 {
      margin-left: 10px;
      font-size: 12px;
      color: $c55;
    }
    .rise-11, .down-11 {
      padding: 1px 5px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 3px;
    }
    .rise-11 {
      @include error-bg(0.1);
      color: $error;
    }
    .down-11 {
      @include success-bg(0.1);
      color: $success;
    }
  }
  .line-5502 {
    margin-top: 23px;
    align-items: flex-end;
  }
  .line-5503 {
    margin-top: 23px;
  }
  .line-5506 {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;

    span { margin-top: 10px; }
    span:first-child { margin-top: 0; }
  }
  .line-5505 {
    margin-top: 0;
  }
}

.card-1105 {
  justify-content: space-between;
}
