@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.welfare-ranks-left {
  margin-top: 40px;
  position: relative;
  .welfare-ranks-content {
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    height: 100%;
    .welfare-ranks-item {
      display: flex;
      justify-content: flex-start;
      padding: 10px 0;
      .welfare-ranks-name {
        display: block;
        width: 80px;
        margin-right: 20px;
        text-align: right;
      }
      p {
        @include primary-bg(1);
        border-radius: 0 5px 5px 0;
        height: 14px;
        margin-top: 3px;
      }
    }
  }
  .welfare-ranks-lines {
    @include black-bg(0.1);
    width: 1px;
    height: 505px;
    position: absolute;
    left: 90px;
    top: 0;
    display: block;
  }
}
.welfare-ranks-left-current {
  @include primary-bg(0.02);
  border-radius: 5px 0 0 5px;
}
