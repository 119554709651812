@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.title-111 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .big-111 {
    position: absolute;
    left: 15px;
    top: -5px;
    display: inline-flex;
    align-items: flex-end;

    img {
      width: 25px;
      height: 25px;
    }
    strong {
      margin-left: 10px;
      font-size: 30px;
      font-weight: 500;
      line-height: 1;
      opacity: 0.25;
      color: $ccc;
    }
  }
  .title-113 {
    display: inline-flex;
    align-items: flex-end;
    padding-top: 10px;
    z-index: 5;

    .span-11 {
      display: inline-flex;
      width: 4px;
      height: 20px;
      background-color: $secondary;
    }
    b {
      margin-left: 10px;
      font-size: 20px;
      line-height: 1;
      font-weight: 600;
    }
    .indi-11 {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
      cursor: pointer;
      color: $primary;
    }
  }
}
