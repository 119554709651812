@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.sider_10 {
  display: block;
  align-items: center;
  width: 200px;
  z-index: 500;
  padding: 10px 0 10px 10px;
  border-radius: 0 10px 10px 0;
  background-color: $f7;
}

.fixed_10 {
  position: fixed;
  // top: 385px;
  bottom: 0;
  overflow-y: auto;
}

@media (max-width: 1920px) {
  .fixed_10 { left: 0; }
}

@media (min-width: 1920px) {
  .fixed_10 { left: calc((100% - 1920px) / 2); }
}
