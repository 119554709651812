@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.dash-change-warp {
  background: $white;
  width: 524px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 20px;
  height: 444px;
  display: flex;
  flex-direction: column;
  .item-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    h3 {
      font-size: 16px;
      font-weight: 500;
      color: $black;
    }
    span {
      font-size: 12px;
      font-weight: 400;
      color: $c87;
    }
  }
  .zent-sortable {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .sort-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      border-radius: 5px;
      margin-top: 20px;
      cursor: pointer;
      .item-content {
        flex: 1;
        margin-left: 10px;
        background: $f7;
        height: 100%;
        border: 1px solid $ec;
        padding: 20px 0 8px 20px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        h3 {
          font-size: 14px;
          font-weight: 500;
          color: $black;
        }
        .show-model {
          display: flex;
          justify-content: flex-start;
          b {
            font-size: 12px;
            color: $c55;
            height: 16px;
          }
          span {
            display: flex;
            align-items: center;
            padding: 0 10px;
            font-size: 12px;
            height: 16px;
            color: $primary;
            border-right: 1px solid $c87;
            font-weight: 500;
            &:last-child{
              border: 0;
            }
          }
        }
      }
    }
  }
  .change-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .cancel {
      width: 100px;
      height: 36px;
      background: $white;
      border-radius: 5px;
      border: 1px solid $ec;
      font-weight: 400;
      color: $c87;
      text-align: center;
      line-height: 36px;
      cursor: pointer;
    }
    .canok {
      width: 100px;
      height: 36px;
      background: $primary;
      border-radius: 5px;
      border: 1px solid $ec;
      font-weight: 400;
      color: $white;
      text-align: center;
      line-height: 36px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
}
