@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.content-011 {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  margin-bottom: 40px;

  .dash-change-warp {
    background: $white;
    width: 900px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    padding: 20px 20px 0 20px;
    height: 700px;
    display: flex;
    flex-direction: column;
    .zent-sortable {
      display: flex;
      justify-content: flex-start;
      margin-top: 10px;
      .sort-item {
        @include white-bg(0.12);
        height: 40px;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        color: $secondary;
        margin-right: 15px;
        padding: 0 10px;
        cursor: pointer;
        position: relative;
        span {
          @include white-bg(0.6);
          color: $c333;
          width: 16px;
          height: 16px;
          display: block;
          border-radius: 50%;
          border: 1px solid $darkBg;
          position: absolute;
          right: -8px;
          top: -8px;
          line-height: 10px;
        }
      }
    }
  }
  .content-015 {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 40px;
  }
  .content-0011 {
    width: 100%;
    // max-width: 1840px;
  }
}
