@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.company-list_10 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .company-55 {
    @include white-bg(1);
    position: relative;
    width: 450px;
    display: flex;
    flex-direction: column;
    padding: 40px 0 10px 0;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

    .title-55 {
      @include white-bg(1);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      z-index: 5;
      border-radius: 5px 5px 0 0;
      border-bottom: 1px solid $ec;

      span {
        @include black-color(1);
        font-size: 14px;
        font-weight: 600;
      }
    }
    .content-55 {
      height: 286px;
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      overflow-y: auto;

      .line-55 {
        display: inline-flex;
        align-items: flex-end;
        margin: 7px 0;

        span {
          flex: 1;
          display: inline-flex;
          margin-right: 20px;
        }
        small {
          @include mont-font();
          line-height: 1.6;
          color: $da;
        }
      }
    }
  }
}
