@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.company-sample_10 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .sample-55 {
    @include white-bg(1);
    display: flex;
    flex-direction: column;
    width: 440px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

    .line-99 {
      display: inline-flex;
      justify-content: center;
      margin-top: 30px;
    }

    .filter-10 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .filter-11 {
      position: relative;
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      max-height: 266px;
      min-height: 120px;
      // padding-top: 32px;
      margin-top: 30px;
      // overflow-y: auto;

      .text-11 {
        font-size: 14px;
        line-height: 1;
      }
      .line-31 {
        display: inline-flex;
        align-items: center;
        margin-top: 10px;

        .little-11 {
          margin: 0 30px;
          font-size: 14px;
          font-weight: 500;
          color: $c55;
        }
        .little-13 {
          font-size: 14px;
          font-weight: 500;
          color: $black;
        }
      }
      .line-51 {
        display: inline-flex;
        align-items: center;
        width: 160px;
        height: 36px;
        padding: 0 10px;
        border-radius: 5px;
        background-color: $f7;
      }
      .line-61 {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
        margin-bottom: 8px;

        &:last-child { margin-bottom: 0; }
        .small-13 {
          @include mont-font();
          font-size: 12px;
          line-height: 1;
          color: $da;
        }
      }
      .line-66 {
        display: inline-flex;
        width: 100%;
        padding-bottom: 8px;
        border-bottom: 1px solid $ec;
      }
      .filter-111 {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }
      .search-66 {
        position: relative;
        display: inline-flex;
        align-items: center;
        padding: 0;
      }
    }
    .mark-111 {
      display: inline-flex;
      flex-direction: column;
      margin-top: 10px;

      .warning-11 {
        font-size: 12px;
        font-weight: 500;
        color: $error;
      }
      .hidden-11 { visibility: hidden; }
      .show-11 { visibility: visible; }
      .desc-11 {
        padding: 10px;
        margin-top: 10px;
        font-size: 12px;
        font-weight: 500;
        border: 1px solid $ec;
        border-radius: 5px;
        color: $c55;
        background-color: $f7;
      }
    }
  }
}
