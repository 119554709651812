@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.human-common-pdf {
  background-color: $white;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 10px;
  .tabs {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  .time-tabs {
    margin-top: 20px;
  }
  .output-detail-top {
    padding: 25px 20px 5px 20px;
    border-radius: 5px;
    margin-top: 20px;
    background-color: $f7;
    overflow: hidden;
    p {
      font-weight: 600;
      color: $black;
    }
    .item {
      float: left;
      margin: 20px 0;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        font-size: 16px;
        color: $c55;
        font-weight: 600;
        margin-left: 10px;
      }
      i {
        display: block;
        width: 38px;
        height: 38px;
        background-color: $white;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center center;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
        border-radius: 50%;
      }
    }
    b {
      clear: both;
      display: block;
    }
  }
  .chart-detail {
    padding: 25px 20px;
    border-radius: 5px;
    margin-top: 5px;
    overflow-y: auto;
    color: $c55;
    font-size: 12px;
    .item {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 6px;
      &:first-child {
        margin-top: 0;
      }
      b {
        display: block;
        width: 3px;
        height: 3px;
        background-color: $c55;
        margin-right: 5px;
        border-radius: 50%;
        margin-top: 7px;
      }
      p {
        color: $c55;
        flex: 1;
        // .t-red {
        //   color: $error;
        //   background-color: transparent;
        // }
        // .t-yellow {
        //   color: $secondary;
        //   background-color: transparent;
        // }
        // .t-blue {
        //   color: $success;
        //   background-color: transparent;
        // }
        // .category {
        //   @include primary-bg(0.3);
        //   color: $primary;
        //   border-radius: 3px;
        //   padding: 0 2px;
        //   margin: 0 3px;
        // }
        span {
          @include mont-font();
          color: $c55;
          font-weight: 400;
        }
        // .change_ratio {
        //   margin-left: 2px;
        // }
      }
    }
  }
}
