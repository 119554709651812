@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.non-auth-11 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 1155px;
  padding-top: 67px;
  margin-top: 17px;
  background: linear-gradient(0deg, #FFF 0%, #CBD0FF 100%);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  strong {
    @include primary-color(1);
    font-size: 16px;
    font-weight: 600;
  }
  span {
    margin-top: 20px;
    font-size: 14px;
    color: rgba(72, 82, 89, 0.7);
  }
}
