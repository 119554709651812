@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.dash-rival-warp {
  .title {
    padding: 30px 0 0 0;
  }
  .dash-effect-warp {
    position: relative;
    margin-top: 20px;
    background: $fff;
    border-radius: 5px;
    cursor: pointer;
    .non-auth-11 {
      height: 230px;
    }
    .position-title {
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      color: $black;
      opacity: 0.1;
      line-height: 56px;
      width: 100%;
    }
    .dash-industry-content {
      // height: 218px;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 1;
      .top {
        display: flex;
        justify-content: space-between;
        // border-bottom: 1px solid $ec;
        // padding-bottom: 20px;
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: $black;
        }
        .check {
          height: 36px;
          background: $f7;
          border-radius: 5px;
          border: 1px solid $ec;
          display: flex;
          align-items: center;
          padding: 0 2px;
          font-weight: 500;
          color: $black;
          a {
            padding: 0 15px;
            line-height: 34px;
            line-height: 32px;
            font-size: 12px;
            cursor: pointer;
          }
          .active {
            background-color: $primary;
            color: $white;
            border-radius: 5px;
          }
        }
        p {
          font-weight: 500;
          color: $primary;
          line-height: 36px;
          cursor: pointer;
        }
      }
      .contents {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        flex: 1;
        .top {
          font-size: 18px;
          font-weight: 600;
          color: $black;
        }
        h4 {
          font-weight: 400;
          color: $c55;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            background-color: $error;
            color: $white;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            border-radius: 3px;
            padding: 0 2px;
            margin-left: 2px;
          }
        }
        .left {
          width: 20%;
          padding: 10px;
          text-align: center;
          cursor: pointer;
          .empty-101 img{
            width: 90px;
            height: auto;
          }
          // span {
          //   font-size: 12px;
          //   color: $c55;
          // }
          .recruit-chart {
            width: 100%;
            height: 100px;
            canvas {
              cursor: pointer;
            }
          }
        }
        .special {
          width: 20%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          padding: 10px;
          border-left: 1px solid $ec;
          cursor: pointer;
          .empty-101 img{
            width: 90px;
            height: auto;
          }
          h4 {
            margin-bottom: 5px;
          }
          .type-items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width: 200px;
            span {
              margin-top: 10px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background: $f7;
              border-radius: 5px;
              border: 1px solid $ec;
              padding: 0 3px;
              display: flex;
              width: 90px;
              justify-content: space-between;
              font-size: 12px;
              i {
                @include mont-font();
                color: $error;
              }
              label {
                cursor: pointer;
              }
            }
            .item {
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }
        }
        .mid {
          width: 20%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          padding: 10px;
          border-left: 1px solid $ec;
          cursor: pointer;
          .empty-101 img{
            width: 90px;
            height: auto;
          }
          b {
            @include mont-font();
            font-weight: 600;
            color: $black;
            font-size: 24px;
            cursor: pointer;
            margin-top: 10px;
          }
          .change-radio {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 30px;
            a {
              color: $c55;
              margin-right: 5px;
            }
            i {
              @include mont-font();
            }
            label {
              @include error-bg(0.1);
              color: $error;
              border-radius: 2px;
              padding: 0 2px;
              font-size: 12px;
              cursor: pointer;
            }
          }
          .citys {
            margin-top: 30px;
            a {
              color: $c55;
              margin-right: 5px;
            }
            span {
              color: $black;
              font-weight: 600;
            }
          }
        }
        .right {
          width: 20%;
          display: flex;
          align-items: center;
          flex-direction: column;
          padding: 10px;
          border-left: 1px solid $ec;
          cursor: pointer;
        }
      }
    }
  }
  .dash-report-warp {
    position: relative;
    margin-top: 20px;
    .dash-report-content {
      display: flex;
      justify-content: flex-start;
      background: $fff;
      border-radius: 5px;
      .left {
        width: calc(100% - 240px);
        height: 180px;
        padding: 15px 30px 10px 20px;
        h3 {
          font-size: 18px;
          font-weight: 600;
          color: $black;
        }
        .content {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px;
          .items {
            width: 20%;
            height: 120px;
            background: $f7;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding-bottom: 15px;
            padding-top: 15px;
            p {
              display: flex;
              justify-content: flex-end;
              width: 64px;
              height: 20px;
              background: $primary;
              font-weight: 500;
              border-radius: 0 10px 10px 0;
              align-items: center;
              color: $white;
              padding-right: 10px;
            }
            h4 {
              margin-left: 20px;
              font-size: 16px;
              font-weight: 500;
              color: $black;
            }
            span {
              font-size: 12px;
              font-weight: 400;
              color: $c87;
              margin-left: 20px;
            }
          }
        }
      }
      .right {
        padding: 20px 0;
        background: linear-gradient(180deg, #fcd577 0%, #f8ab42 100%);
        border-radius: 0 10px 10px 0;
        width: 240px;
        height: 180px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-end;
        padding: 20px 10px 20px;
        .empty-101 img{
          width: 90px;
          height: auto;
        }
        a {
          width: 72px;
          border-bottom: 1px solid $white;
          color: $white;
          font-size: 12px;
          cursor: pointer;
        }
        h4 {
          font-size: 16px;
          font-weight: 500;
          color: #5b3400;
          line-height: 16px;
          width: 100%;
          text-align: center;
        }
        span {
          @include mont-font();
          width: 100%;
          font-size: 20px;
          font-weight: 600;
          color: $white;
          text-align: center;
        }
        p {
          width: 140px;
          height: 36px;
          border-radius: 20px;
          border: 1px solid $white;
          margin: 0 auto;
          text-align: center;
          line-height: 34px;
          font-weight: 500;
          color: $white;
          cursor: pointer;
        }
      }
    }

    .position-title {
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      color: $black;
      opacity: 0.1;
      line-height: 56px;
      width: 100%;
    }
  }
}
