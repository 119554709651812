@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.content_10 {
  display: flex;
  flex-direction: column;
}

@media (max-width: 1350px) {
  .content_10 { padding-left: 220px; }
}
@media (min-width: 1350px) and (max-width: 1600px) {
  .content_10 { padding-left: 180px; }
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .content_10 { padding-left: 140px; }
}

@media (min-width: 1920px) {
  .content_10 { padding-left: 220px; }
}
