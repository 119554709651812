@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.slick-100 {
  width: 50%;
  display: flex;
  padding: 10px;

  .slick-1001 {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  .slick-1002 {
    background-image: url('https://file.smartsalary.cn/web-img%402.0/home-banner/company-warp-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .slick-1003 {
    background-image: url('https://file.smartsalary.cn/web-img%402.0/home-banner/industry-warp-banner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .slick-1004 {
    background-image: url('https://file.smartsalary.cn/web-img%402.0/home-banner/book-warp-bannner.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .dots-100 {
    li {
      button::before {
        width: 10px;
        height: 10px;
        opacity: 0.5;
        color: $fff;
      }
    }
    li.slick-active {
      button::before {
        opacity: 1;
      }
    }
  }

  .line-151 {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;

    b {
      font-size: 24px;
      font-weight: 600;
      color: $fff;
    }
    p {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      span {
        font-size: 14px;
        line-height: 24px;
        color: $fff;
      }
    }
  }
  .pic-11 {
    width: 70%;
    margin-top: 60px;
  }
}
