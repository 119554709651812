@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';
@import '@scss/media.scss';

.sub-footer {
  @include min-width();
  @include max-width();
  margin-top: 20px;
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0 82px;
  height: 70px;

  p {
    display: inline-flex;
    justify-content: center;

    &.left_market {
      width: 280px;
      visibility: hidden;
    }
    &.center_market {
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: $fff;
      opacity: 0.5;
      a {
        color: $fff;
        opacity: 0.8;
      }
    }
    &.right_market {
      width: 280px;
      align-items: center;
      justify-content: flex-end;
      font-size: 12px;

      span {
        @include white-color(1);
        cursor: pointer;
      }
      b {
        margin: 0 12px;
      }
    }
  }
}
