@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.typical-position_10 {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 10px;
  border-radius: 8px;
  background-color: $f7;

  strong.sub-11 {
    @include black-color(0.85);
    margin-top: 10px;
    margin-left: 6px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
  }
  .position-11 {
    @include white-bg(1);
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    padding: 10px 30px 20px 30px;
    border-radius: 8px;

    .line-11 {
      display: inline-flex;
      align-items: center;

      span {
        @include black-color(0.5);
        display: inline-flex;
        align-items: center;
        padding: 10px;
        font-size: 12px;
        line-height: 1;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
      }
      .span-10 { width: 150px; }
      .span-11 {
        flex: 1;
        margin-left: 20px;
      }
    }

    .section-11 {
      display: flex;
      flex-direction: column;

      .section-13 {
        display: inline-flex;

        b, p {
          display: inline-flex;
          align-items: center;
          // min-height: 54px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        }
        b {
          @include black-color(0.85);
          width: 150px;
          padding: 10px;
        }
        p {
          flex: 1;
          flex-wrap: wrap;
          padding: 10px 0;
          margin-left: 20px;
        }
      }
    }
  }

  .section-111 {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin-top: 20px;
    padding: 0 30px;
    border-radius: 4px;
    background: linear-gradient(270deg,rgba(52,63,153,.5),#343f99);

    .section-113 {
      display: inline-flex;

      .line-01 {
        display: inline-flex;
        align-items: center;

        span {
          @include white-color(0.85);
          margin: 0 10px 0 4px;
          font-size: 14px;
          line-height: 1;
        }
        i {
          @include white-color(1);
          @include mont-font();
          font-weight: 600;
          font-size: 16px;
          line-height: 1;
        }
      }
      .line-03 {
        margin-left: 30px;
      }
    }
  }
}
