@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.collect-card-11 {
  @include white-bg(1);
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 10px 20px;
  border-radius: 5px;

  .section-6001 {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;

    .text-6001 {
      font-size: 12px;
      font-weight: 500;
      line-height: 1;
      color: $da;
    }
    .value-6001 {
      display: inline-flex;
      align-items: flex-end;

      svg {
        position: relative;
        margin-right: 2px;
        bottom: 2px;
      }
    }
    b {
      margin-right: 3px;
      font-size: 12px;
      font-weight: 600;
      line-height: 1.2;
    }
    strong {
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
    }
    .error-55 { color: $error; }
    .success-55 { color: $success; }
    .value-6006 {
      display: inline-flex;

      .line-55 {
        display: inline-flex;
        flex-direction: column;
      }
      .rise-11, .down-11 {
        line-height: 1;
        padding: 2px 5px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 3px;
      }
      .rise-11 {
        @include error-bg(0.1);
        color: $error;
      }
      .down-11 {
        @include success-bg(0.1);
        color: $success;
      }
      span { margin-top: 6px; }
      span:first-child { margin-top: 0; }
    }
  }
  .section-6006 {
    padding-bottom: 6px;
  }
}
