@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.box-111 {
  @include white-bg(1);
  display: flex;
  flex-direction: column;
  padding: 20px 10px 20px 20px;
  margin-top: 20px;
  border-radius: 4px;

  .tab-1010 {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    margin-top: 30px;
  }
  .content-1010 {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
  }
  .content-1011 {
    display: flex;
    flex-direction: column;
    margin: 30px 0 10px 0;
  }
  .content-1015 {
    display: flex;
    margin: 30px 0;
  }
  .content-1030 {
    flex: 1;
    display: flex;
    padding-right: 10px;
  }
  .industry-detail_11 {
    height: 335px;
  }
  .trend-1511 {
    display: flex;
    flex-wrap: wrap;

    .trend-1515 {
      width: calc(33.33% - 10px);
      display: flex;
      margin-top: 40px;

      &:first-child, &:nth-child(4) { width: calc(33.33% + 20px); }
    }
  }
  .chart-detail_11 {
    width: 410px;
    padding: 30px;
    background-color: $f7;
    border-radius: 5px;
    margin-top: 5px;
    overflow-y: auto;

    .item {
      display: flex;
      // justify-content: flex-start;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
      .circle-111 {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
      }
      .line-111 {
        flex: 1;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;

        .span-11 { line-height: 1.5; }
        .red-11, .blue-11, .green-11 {
          @include mont-font();
          margin-left: 4px;
          font-weight: 500;
          line-height: 1.5;
        }
        .red-11 { color: $error; }
        .blue-11 { color: $primary; }
        .green-11 { color: $success; }
        .name-11 {
          @include primary-bg(0.2);
          color: $primary;
          border-radius: 2px;
          padding: 0 2px;
          margin: 0 3px;
        }
        .high-11, .low-11 {
          border-radius: 2px;
          padding: 0 2px;
          margin: 0 3px;
        }
        .high-11 {
          @include error-bg(0.2);
          color: $error;
        }
        .low-11 {
          @include success-bg(0.2);
          color: $success;
        }
        .bold-11 {
          @include mont-font();
          font-weight: 600;
          line-height: 1;
        }
      }
      .line-1115 {
        display: block;
      }
      b {
        display: block;
        width: 6px;
        height: 6px;
        background-color: $f7;
        border: 1px solid $cf;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
  }
  .content-151 {
    display: flex;
    flex-direction: column;
  }
  .content-152 { margin-bottom: 30px; }
  .content-153 { margin-top: 20px; }
  .left-1011 {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .right-1011 {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .line-1011 {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-left: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $ec;

    strong {
      font-size: 16px;
      font-weight: 600;
      line-height: 1;
    }
    .text-1011 {
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
      color: $da;
    }
  }
  .line-1019 { padding-left: 30px; }
  .fee-1011 {
    width: calc(33.33% + 20px);
    display: flex;
    flex-direction: column;

    .line-1011 { margin-bottom: 10px; }
    &:nth-child(2) {
      width: calc(33.33% - 10px);
    }
    &:nth-child(3) {
      width: calc(33.33% - 10px);
    }
  }
  .trend-6060 {
    width: 100%;
    display: flex;
    flex-direction: column;

    .line-1011 {
      margin-bottom: 10px;
      padding-left: 10px;
      border-bottom: none;
    }
  }
  .trend-6066 {
    .line-1011 { padding-left: 30px; }
  }
}
