@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.capital-analysis-warp-pdf {
  .capital-top {
    display: flex;
    justify-content: space-between;
    .add-date {
      background-color: $secondary;
      border-radius: 5px;
      width: 90px;
      height: 36px;
      font-size: 12px;
      text-align: center;
      line-height: 36px;
      color: $white;
      cursor: pointer;
    }
  }
  .chart-check {
    color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      margin-right: 5px;
    }
  }
  .chart-demo {
    display: flex;
    margin-top: 30px;
    .chart {
      flex: 1;
      margin-right: 10px;
      .capital-chart-warp-pdf {
        .chart-check {
          font-size: 12px;
          font-weight: 600;
          color: $primary;
          margin-left: 59px;
        }
        .chart-top {
          display: flex;
          justify-content: flex-start;
          margin-top: 8px;
          .chart-left-title {
            width: 14px;
            color: $black;
            font-weight: 600;
            span {
              color: $da;
            }
          }
          .chart-left-num {
            width: 20px;
            position: relative;
            text-align: right;
            margin-left: 20px;
            span {
              @include mont-font();
              @include black-color(0.5);
              display: block;
              width: 20px;
              font-size: 12px;
              &:nth-child(1) {
                position: absolute;
                left: 0;
                bottom: 0;
              }
              &:nth-child(2) {
                position: absolute;
                left: 0;
                bottom: 20%;
                margin-bottom: -9px;
              }
              &:nth-child(3) {
                position: absolute;
                left: 0;
                bottom: 40%;
                margin-bottom: -9px;
              }
              &:nth-child(4) {
                position: absolute;
                left: 0;
                bottom: 60%;
                margin-bottom: -9px;
              }
              &:nth-child(5) {
                position: absolute;
                left: 0;
                bottom: 80%;
                margin-bottom: -9px;
              }
              &:nth-child(6) {
                position: absolute;
                left: 0;
                top: 0;
                margin-top: -5px;
              }
            }
          }
          .chart-content {
            flex: 1;
            height: 406px;
            background-image: url(https://file.smartsalary.cn/web-img%402.0/home/1011.png);
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-left: 5px;
            position: relative;
            border-radius: 5px;
            b {
              box-shadow: 0 0 5px 0 rgba(224, 32, 32, 0.2);
              border-radius: 2px;
              background-color: $white;
              height: 20px;
              line-height: 20px;
              text-align: center;
              position: absolute;
              left: 3%;
              top: 12px;
              width: 50px;
              &:nth-child(2) {
                color: $error;
              }
              &:nth-child(3) {
                color: $secondary;
                left: 33%;
              }
              &:nth-child(4) {
                color: $success;
                left: 73%;
              }
            }
            .item-pointer {
              display: flex;
              flex-direction: column;
              align-items: center;
              color: $black;
              font-size: 12px;
              flex-wrap: nowrap;
              width: auto;
              margin-left: -6px;
              margin-bottom: -6px;
              position: relative;
              i {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: $primary;
                cursor: pointer;
              }
              span {
                background-color: $white;
                border-radius: 8px;
                padding: 0 3px;
                position: absolute;
                width: 75px;
                top: 16px;
                text-align: center;
                border: 1px solid $ec;
                z-index: 99;
              }
            }
            .show-table {
              display: flex;
              justify-content: flex-start;
              flex-wrap: wrap;
              position: absolute;
              left: 0;
              top: 0;
              background-color: transparent;
              width: 100%;
              height: 100%;
              border-radius: 5px;
              span {
                display: block;
                width: 20%;
                height: 20%;
                border: 2px solid $f3f4;
                border-radius: 5px;
              }
            }
          }
        }
        .chart-bot-num {
          margin-left: 59px;
          position: relative;
          width: calc(100% - 59px);
          height: 20px;
          span {
            @include mont-font();
            @include black-color(0.85);
            display: block;
            width: 20px;
            font-size: 12px;
            &:nth-child(1) {
              position: absolute;
              left: 0;
              top: 5px;
            }
            &:nth-child(2) {
              position: absolute;
              top: 5px;
              left: 20%;
              margin-left: -10px;
            }
            &:nth-child(3) {
              position: absolute;
              left: 40%;
              top: 5px;
              margin-left: -10px;
            }
            &:nth-child(4) {
              position: absolute;
              top: 5px;
              left: 60%;
              margin-left: -10px;
            }
            &:nth-child(5) {
              position: absolute;
              left: 80%;
              top: 5px;
              margin-left: -10px;
            }
            &:nth-child(6) {
              position: absolute;
              right: 0;
              top: 5px;
              margin-right: -5px;
            }
          }
        }
        .chart-bot {
          text-align: center;
          margin-top: 20px;
          font-weight: 600;
          color: $black;
          span {
            color: $da;
          }
        }
      }
    }
    .capital-detail {
      height: 405px;
      margin-top: 25px;
    }
  }
}
