@import '@scss/color.scss';
@import '@scss/global.scss';
@import '@scss/mixin.scss';

.market-query-modal_111 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 500px;
  height: 286px;
  padding-bottom: 30px;
  border-radius: 8px;
  background-color: $fff;

  .line-111 {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 0 20px;
    border-bottom: 1px solid $ec;

    b {
      font-size: 16px;
      font-weight: 600;
      color: $c55;
    }
  }

  .line-113 {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 50px;

    strong {
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      color: $black;
    }
    span {
      margin-top: 15px;
      font-size: 12px;
      color: $c55;
    }
  }
}
