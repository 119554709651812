@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.big-title-warp {
  position: relative;
  b {
    display: block;
    width: 460px;
    height: 0;
    border-bottom: 30px solid $df9;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    margin: 0 auto;
  }
  i {
    display: block;
    height: 40px;
    border-radius: 20px 20px 0 0;
    background-color: $white;
  }
  span {
    width: 400px;
    text-align: center;
    position: absolute;
    left: 50%;
    color: $white;
    z-index: 1;
    top: 15px;
    margin-left: -200px;
    font-size: 20px;
    font-weight: 600;
  }
  p {
    text-align: center;
    color: $white;
    width: 400px;
    position: absolute;
    border-top: 60px solid $secondary;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    font-size: 20px;
    font-weight: 600;
    left: 50%;
    margin-left: -200px;
    top: 0;
  }
}
