@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.welfare-budget-warp {
  width: calc(50% - 5px);
  background-color: $f7;
  padding: 20px 10px 10px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .two-sub-title {
    font-size: 12px;
    color: $c87;
    margin-top: 10px;
  }
  .budget-scale {
    flex: 1;
    display: inline-flex;
    flex-direction: column;

    .value {
      @include mont-font;
    }
    .one {
      span {
        background-color: $t7One;
      }
    }
    .two {
      span {
        background-color: $t7Two;
      }
    }
    .three {
      span {
        background-color: $t7Three;
      }
    }
    .four {
      span {
        background-color: $t7Four;
      }
    }
    .five {
      span {
        background-color: $t7Five;
      }
    }
    .six {
      span {
        background-color: $t7Six;
      }
    }
    .seven {
      span {
        background-color: $t7Seven;
      }
    }
    .success {
      span {
        background-color: $t7Seven;
      }
    }

    .progres-hover-item,
    .progress_0911 {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      margin-left: 20px;
      cursor: pointer;
      padding: 8px 10px;
      border-radius: 4px;

      & > p {
        flex: 1;
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        span {
          @include mont-font;
          width: 100%;
          line-height: 1;
        }
      }
      .value {
        @include black-color(1);
        font-size: 16px;
        font-weight: 600;
        margin-left: 5px;
        display: block;
        width: 45px;
      }
    }
    .progres-hover-item {
      @include primary-bg(0.04);
    }
    .progress_0911:hover {
      @include primary-bg(0.04);
    }
  }
  .budget-item {
    display: flex;
    margin-top: 20px;
    border-radius: 8px;
    background-color: $fff;
    padding: 20px;
    flex: 1;
  }
}
