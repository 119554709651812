@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.industry-alert_55 {
  display: flex;
  flex-direction: column;
  width: 440px;
  height: 230px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  background-color: $fff;

  .line-01 {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    margin: 0 20px;
    border-bottom: 1px solid $ec;

    b {
      font-weight: 600;
      font-size: 16px;
    }
  }
  .line-02 {
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 2;
  }
  .line-03 {
    display: inline-flex;
    justify-content: center;
    margin-bottom: 20px;
  }
}
