@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.industry-research-pdf_10 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .content-chain-1001 {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .title-1001 {
    display: flex;
    flex-direction: column;
    margin-top: 26px;
  }
}
