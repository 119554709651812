@import "@scss/color.scss";
@import "@scss/global.scss";
@import "@scss/mixin.scss";

.head-table-warp {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  .table-left {
    border-radius: 5px 0 0 5px;
    overflow: hidden;
    .top-none {
      height: 36px;
      background-color: $f7;
      border-bottom: 1px solid $white;
    }
    .title {
      display: flex;
      justify-content: flex-start;
      background-color: $f7;
      b {
        display: block;
        width: 140px;
        text-align: center;
        height: 36px;
        line-height: 36px;
        color: $c55;
        &:first-child {
          text-align: left;
          text-indent: 10px;
        }
      }
    }
    .content {
      display: flex;
      justify-content: flex-start;
      background-color: $f7;
      &:nth-child(odd) {
        background-color: $white;
      }
      b {
        @include mont-font();
        display: block;
        width: 140px;
        text-align: center;
        height: 36px;
        line-height: 36px;
        color: $c55;
        &:first-child {
          text-align: left;
          text-indent: 10px;
        }
      }
    }
  }
  .table-right {
    flex: 1;
    overflow-y: auto;
    border-radius: 0 5px 5px 0;
    width: 100%;
    .top-title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      b {
        @include primary-bg(0.1);
        display: block;
        border-left: 1px solid $white;
        border-bottom: 1px solid $white;
        float: left;
        text-align: center;
        line-height: 35px;
      }
    }
    .mid-title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      .title {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        color: $c55;
        b {
          @include primary-bg(0.1);
          display: block;
          border-left: 1px solid $white;
          text-align: center;
          float: left;
          line-height: 36px;
          font-size: 12px;
        }
      }
    }
    .right-content {
      .right-content-item {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        &:nth-child(odd) {
          p {
            b {
              background-color: $white;
            }
          }
        }
        p {
          float: left;
          width: 100%;
          b {
            background-color: $f7;
            display: block;
            text-align: center;
            float: left;
            line-height: 36px;
            @include mont-font();
          }
        }
      }
    }
  }
}

.com-bottom {
  display: flex;
  justify-content: flex-start;
  max-height: 360px;
  overflow-y: auto;
  .content {
    display: flex;
    justify-content: flex-start;
    background-color: $f7;
    &:nth-child(odd) {
      background-color: $white;
    }
    b {
      @include mont-font();
      display: block;
      width: 140px;
      text-align: center;
      height: 36px;
      line-height: 36px;
      color: $c55;
      &:first-child {
        text-align: left;
        text-indent: 10px;
      }
    }
  }
  .right-content {
    .right-content-item {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      &:nth-child(odd) {
        p {
          b {
            background-color: $white;
          }
        }
      }
      p {
        float: left;
        width: 100%;
        b {
          background-color: $f7;
          display: block;
          text-align: center;
          float: left;
          line-height: 36px;
          @include mont-font();
        }
      }
    }
  }
}
